<script lang="ts">
    export default defineNuxtComponent({
        setup(_, ctx) {
            const show = ref<boolean>(false);
            const bgCssClass = 'checkered-bg';

            if (import.meta.client) {
                show.value = inject<boolean>('isDebug', false);
            }

            return () => {
                const slot = ctx.slots.default?.({ bgCssClass });

                if (show.value && slot) {
                    return slot;
                }

                return null;
            };
        }
    });
</script>
